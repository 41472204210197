export interface ProductPrice {
	priceId: string
	amount: number
	currency: string
	recurring: string
}

export interface Product {
	id: string
	name: string
	transactionLimit: string
	features: string[]
	prices: ProductPrice[]
	letsChat: boolean
}

export interface MyPayment {
	id: string
	active: boolean
	status: string
	product: Product
	subExpiry: string
}

export interface PaymentCheckout {
	sessionUrl: string
}

export enum PaymentStatus {
	UNPAID = 'unpaid',
	TRIALING = 'trialing',
	PAID = 'paid',
	CANCELLED = 'cancelled',
	NOT_FOUND = 404,
}

export interface BillingPortal {
	sessionUrl: string
}
