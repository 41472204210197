
import { defineComponent, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { PaymentStatus } from '@/types/payment'
import { useRouter } from 'vue-router'
import { RouteNames } from '@/types/routes'

export default defineComponent({
	components: {},
	setup() {
		const store = useStore()
		const router = useRouter()

		store.dispatch('onboardingEntry/getOnboardingEntry')
		store.dispatch('registration/getRegistrationDetails')
		store.dispatch('payments/payment/myPayment')
		store.dispatch('user/me/getUserInfo')

		const registrationDataLoader = computed(() => store.getters['registration/isLoaded'])
		const paymentDataLoader = computed(() => store.getters['payments/payment/isLoaded'])
		const userDataLoader = computed(() => store.getters['user/me/isLoaded'])
		const userData = computed(() => store.getters['user/me/getState'])
		const { value: registrationData } = computed(() => store.getters['registration/getState'])
		const { value: paymentData } = computed(() => store.getters['payments/payment/getState'])
		// user didn't complete the survey
		const noSurvey = computed(
			() => registrationData.data?.notificationEmail && !registrationData.data?.survey
		)
		// payment was initiated but not PAID
		const isUnpaid = computed(
			() => paymentData.data?.status === PaymentStatus.UNPAID && !paymentData.data?.active
		)
		// user has PAID and Subscription is Active
		const isActive = computed(
			() =>
				[PaymentStatus.TRIALING, PaymentStatus.PAID].includes(paymentData.data?.status) &&
				paymentData.data?.active
		)
		// subscription is cancelled
		const isCancelled = computed(() => paymentData.data?.status === PaymentStatus.CANCELLED)

		// set storeHash to localStorage to be available across the app
		watch(userData.value, (newValue) => {
			localStorage.setItem('[ld]storeHash', newValue.data.store.hash)
			localStorage.setItem('[ld]poeName', newValue.data.store.poeName)
		})

		watch([registrationDataLoader, paymentDataLoader, userDataLoader], (newValues) => {
			// if everything was loaded
			if (newValues.every((val) => val === true)) {
				if (!paymentData.data && (!registrationData.data || noSurvey.value)) {
					router.push({ name: RouteNames.WELCOME })
				} else if (
					(!paymentData.data && registrationData.data?.survey) ||
					isCancelled.value ||
					isUnpaid.value
				) {
					router.push({ name: RouteNames.PAYWALL })
				} else if (isActive.value) {
					router.push({ name: RouteNames.CONNECTION })
				}
			}
		})
	},
})
